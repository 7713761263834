.textInput {
  border: 1px solid;
  border-image-source: linear-gradient(180deg, #BABFC3 86.46%, #BBBFC3 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  box-shadow: 0px 1px 0px 0px #0000000D;
  border-radius: 4px;
  padding: 8px;
  width: 350px;
}

.container {
  background-color: #F6F6F7;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px;
}

.formContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.label {
  font-size: 14px;
  line-height: 20px;
  color: #202223;
  margin-top: 18px;
  margin-bottom: 4px;
}

.h1 {
  color: #202223;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}

.button {
  background-color: #272042;
  border: none;
  color: #FFFFFF;
  padding: 8px;
  width: 350px;
  margin-top: 30px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.8s;
  margin-bottom: 8px;
}

.button:hover:enabled {
  background: #2E294F radial-gradient(circle, transparent 1%, #2E294F 1%) center/15000%;
}

.button:active:enabled {
  background-color: #3B3560;
  background-size: 100%;
  transition: background 0s;
}

.button:disabled {
  background-color: #A49DCE;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.link {
  text-decoration-line: underline;
  color: #272042;
}

.linkContainer {
  margin-top: 22px;
  text-align: center;
}

.errorContainer {
  display: flex;
  margin-top: 4px;
}

input.errorInput, .errorInput input {
  background: #FFF4F4;
  border: 1px solid #D82C0D;
}

.errorText {
  color: #D82C0D;
  margin-left: 4px;
}

.qrContainer {
  margin: 8px;
}

.hintText {
  font-style: italic;
  font-size: 12px;
}

.authInput {
  margin-top: 22px;
}
