.nav {
    background-color: var(--white);
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 0 1.25rem;
}

.tabLink.active {
    border-bottom: 2px solid var(--dark-purple);
}

.longLogoWrapper {
    display: flex;
    align-items: center;
}

.longLogo {
    width: 130px;
    height: 24px;
}

.subTitle {
    margin: 0;
    color: #808080;
    font-weight: 400;
    padding-left: 1.25rem;
}

.mobileLogo {
    display: grid;
    place-items: center;
}

.tabs {
    display: flex;
    align-items: center;
}

.tabLink {
    color: var(--dark-purple);
    font-weight: 400;
    margin: 0 1.5rem 0 0;
    padding-top: 2px;
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 2px solid transparent;
}

.tabLink:hover {
    cursor: pointer;
}

@media screen and (min-width: 1024px) {
    .nav {
        height: 70px;
        padding: 0 3.75rem;
    }
}

.signOut {
    margin-left: 10px;
}
