.tableWrapper {
    width: 100%;
    max-width: 1300px;
    margin-top: 1.5rem;
    margin-bottom: 5rem;
}

.historyTable {
    width: 100%;
}

.historyTable th {
    background-color: rgb(251, 251, 251);
    padding: 1.5rem 1rem;
    font-weight: 600;
}

.historyTable tbody tr {
    background-color: var(--white);
}

.historyTable tbody tr:hover {
    background-color: rgb(239, 247, 255);
}

tbody tr {
    border-top: 2px solid rgb(247, 247, 248);
}

td {
    padding: 1.5rem;
    font-weight: 400;
    text-transform: capitalize;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
    text-align: center;
    color: var(--dark-purple);
}

.tableButton {
    font-size: 0.875rem;
    padding: 8px 14px;
    border-radius: 10px;
    border: 1px solid var(--medium-grey);
}

.tableButton:hover {
    cursor: pointer;
}

.typeElement ~ .typeElement::before {
    content: ', ';
}

.typeArray {
    max-width: 250px;
    line-height: 22px;
}

.searchWrapper {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}

.searchInput {
    border: 1px solid var(--medium-grey);
    border-radius: 50px;
    padding: 10px 1.5rem;
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    color: var(--dark-purple);
    font-family: 'Nunito Sans', sans-serif;
}

.searchInput:focus-visible {
    outline: none;
}

.dropDownTitle {
    font-weight: 400;
    color: var(--dark-purple);
    display: flex;
    align-items: center;
    width: 100%;
}

.dropDown:hover .dropDownMenu {
    opacity: 1;
    visibility: visible;
}

.dropDown:hover .svgArrow {
    animation: rotate180deg 0.1s ease-in-out;
    animation-fill-mode: both;
}

.dropDown {
    margin-right: 2rem;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
}

.dropDownMenu {
    opacity: 0;
    position: absolute;
    visibility: hidden;
    min-width: 205px;
    z-index: 4;
    position: absolute;
    top: 2.5rem;
    left: -4rem;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: var(--light-grey) 2px 2px;
}

.dropDownItem {
    padding: 0.75rem 0;
    color: var(--dark-purple);
    font-size: 0.875rem;
    display: flex;
    align-items: center;
}

.dropDownItem,
label,
.dropDownCheckbox:hover {
    cursor: pointer;
}

.dropDownItem:not(:last-child) {
    border-bottom: 1px solid var(--light-grey);
}

.dropDownCheckbox {
    margin: 0 0.5rem 0 1.25rem;
    border-radius: 4px;
    appearance: none;
    height: 18px;
    width: 18px;
    background-color: var(--light-grey);
}

.sortCheckbox {
    appearance: none;
    height: 20px;
    width: 20px;
}

.sortCheckbox:checked {
    background-image: url('../../assets/svg/check_24px.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.dropDownCheckbox:checked {
    background-image: url('../../assets/svg/Group 676.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.sortOption {
    width: 100%;
}

#sortDropDown {
    width: 225px;
}

.dropDown:nth-of-type(2) .dropDownMenu {
    min-width: 225px;
}

.dropDown:nth-of-type(2) .dropDownItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 1.125rem;
    padding-right: 0.875rem;
}

.noResultsMessage {
    width: 100%;
    text-align: center;
    color: var(--dark-purple);
    font-size: 1.15rem;
    margin-top: 3rem;
}

.typeNumber {
    position: relative;
}

.typeNumber:hover {
    cursor: default;
}

.typeNumber:hover .hoverList {
    opacity: 1;
    visibility: visible;
}

.hoverList {
    position: absolute;
    left: 0;
    padding: 1rem;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: var(--light-grey) 2px 2px;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    text-transform: none;
}

@media screen and (min-width: 1024px) {
    .dropDownCheckbox {
        height: 20px;
        width: 20px;
    }

    .dropDownItem {
        font-size: 1rem;
    }

    .dropDownMenu {
        min-width: 232px;
    }
}

@keyframes rotate180deg {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}


/* Dropdown Button */
.btn {
    font-size: 0.875rem;
    padding: 8px 14px;
    border-radius: 10px 0px 0px 10px;
    border: 1px solid var(--medium-grey);
}
.btnReverse {
    font-size: 0.875rem;
    padding: 8px 14px;
    border-radius: 0px 10px 10px 0px;
    border: 1px solid var(--medium-grey);
}


/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: absolute;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    z-index: 1;
}

/* Links inside the dropdown */
.dropdownContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdownContent a:hover {background-color: #ddd}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdownContent {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.btn:hover, .dropdown:hover .btn  {
    cursor: pointer;
}