.selectAll {
    text-decoration: underline;
    font-weight: 400;
    margin: 0;
    color: var(--dark-purple);
}

.selectAll:hover {
    cursor: pointer;
}
