.resultWrapper {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 11rem;
}

.percent {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 27px;
    margin: 0;
}

.headline {
    width: 100%;
    text-align: center;
    margin-top: 1.5rem;
}

.barContainer {
    width: 100%;
    max-width: 260px;
    height: 10px;
    background: var(--white);
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

}

.bar {
    background: var(--dark-purple);
    height: 10px;
    width: 10%;
    border-radius: 8px 0 0 8px;
}

.progressHeadline {
    width: 100%;
    max-width: 289px;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    line-height: 25px;
}

.trolleyContainer {
    position: relative;
    width: 100%;
    height: 24px;
    max-width: 260px;
}

.trolley {
    position: absolute;
    top: 0px;
    left: 0px;
}

@media screen and (min-width: 1024px) {
    .resultWrapper {
        margin-top: 13rem;
        max-width: 400px;
    }

    .trolleyContainer {
        max-width: 400px;
    }

    .barContainer {
        max-width: 400px;
    }

    .progressHeadline {
        max-width: 400px;
    }
}
