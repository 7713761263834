.pagination {
    margin-bottom: 10rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.middleLinks {
    display: flex;
}

.paginationLink {
    margin: 0 1rem;
    text-decoration: underline;
}

.placeholderDiv {
    width: 2rem;
}
