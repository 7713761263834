.container { }

.input {
  width: 2.5ch;
  padding: 8px;
  border-radius: 8px;
  font-size: 30px;
  text-align: center;
  margin-right: 12px;
  border: 1px solid rgba(109, 113, 117, 0.5);
  text-transform: uppercase;
}