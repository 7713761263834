.cbWrapper {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.625rem;
    border-radius: 5px;
    border: 1px solid var(--medium-grey);
    margin-right: 0.625rem;
    margin-bottom: 0.5rem;
    color: var(--dark-purple);
    background-color: var(--white);
}


.checkbox {
    appearance: none;
    margin-right: 6px;
    height: 17px;
    width: 17px;
    border: 2px solid var(--medium-grey);
    border-radius: 50%;
}

.cbWrapper,.selected,.label,.checkbox:hover {
    cursor: pointer;
}

.selected {
    color: #20a000;
}

.checkbox:checked {
    border: 2px solid #20a000;
    background-image: url('../../assets/svg/Subtract.svg');
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (min-width: 1024px) {
    .cbWrapper {
        border-radius: 10px;
        font-size: 18px;
    }

    .checkbox {
        margin-right: 10px;
        height: 20px;
        width: 20px;
    }
}
