@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
:root {
    --white: #ffffff;
    --blue-grey: #eff7ff;
    --light-grey: #f6f6f7;
    --medium-grey: #d4d4d4;
    --light-purple: #d9ceff;
    --dark-purple: #241d3c;
    --green: #20a000;
    --bg-primary: var(--light-grey);
}

/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
/**
 * Add the correct display in IE.
 */
main {
  display: block;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}
/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}
/* User interaction
 * ========================================================================== */

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Nunito Sans', sans-serif;
    background-color: var(--bg-primary);
    font-size: 16px;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.container {
    max-width: 1440px;
    margin: 0 auto;
}

.wrapper {
    padding: 0 1.25rem;
}

.flex-center {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.bold {
    font-weight: bold;
}

h1 {
    font-size: 1.125rem;
}

h2 {
    font-size: 1rem;
}

h3 {
    font-size: 0.875rem;
}

@media screen and (min-width: 1024px) {
    h1 {
        font-size: 1.25rem;
    }

    h2 {
        font-size: 1.125rem;
    }

    h3 {
        font-size: 1rem;
    }

    .wrapper {
        padding: 0 4.375rem;
    }
}

.NavBar_nav__1FWUI {
    background-color: var(--white);
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 0 1.25rem;
}

.NavBar_tabLink__Pd6bt.NavBar_active__1HRI5 {
    border-bottom: 2px solid var(--dark-purple);
}

.NavBar_longLogoWrapper__2Wtx1 {
    display: flex;
    align-items: center;
}

.NavBar_longLogo__YARWX {
    width: 130px;
    height: 24px;
}

.NavBar_subTitle__38wYx {
    margin: 0;
    color: #808080;
    font-weight: 400;
    padding-left: 1.25rem;
}

.NavBar_mobileLogo__2MS3a {
    display: grid;
    place-items: center;
}

.NavBar_tabs__1sMPf {
    display: flex;
    align-items: center;
}

.NavBar_tabLink__Pd6bt {
    color: var(--dark-purple);
    font-weight: 400;
    margin: 0 1.5rem 0 0;
    padding-top: 2px;
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 2px solid transparent;
}

.NavBar_tabLink__Pd6bt:hover {
    cursor: pointer;
}

@media screen and (min-width: 1024px) {
    .NavBar_nav__1FWUI {
        height: 70px;
        padding: 0 3.75rem;
    }
}

.NavBar_signOut__1KE3S {
    margin-left: 10px;
}

.SelectAll_selectAll__39tlc {
    text-decoration: underline;
    font-weight: 400;
    margin: 0;
    color: var(--dark-purple);
}

.SelectAll_selectAll__39tlc:hover {
    cursor: pointer;
}

.Checkbox_cbWrapper__1WT0A {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.625rem;
    border-radius: 5px;
    border: 1px solid var(--medium-grey);
    margin-right: 0.625rem;
    margin-bottom: 0.5rem;
    color: var(--dark-purple);
    background-color: var(--white);
}


.Checkbox_checkbox__2DiPf {
    -webkit-appearance: none;
            appearance: none;
    margin-right: 6px;
    height: 17px;
    width: 17px;
    border: 2px solid var(--medium-grey);
    border-radius: 50%;
}

.Checkbox_cbWrapper__1WT0A,.Checkbox_selected__2V8Xp,.Checkbox_label__2R9H7,.Checkbox_checkbox__2DiPf:hover {
    cursor: pointer;
}

.Checkbox_selected__2V8Xp {
    color: #20a000;
}

.Checkbox_checkbox__2DiPf:checked {
    border: 2px solid #20a000;
    background-image: url(/static/media/Subtract.98af7175.svg);
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (min-width: 1024px) {
    .Checkbox_cbWrapper__1WT0A {
        border-radius: 10px;
        font-size: 18px;
    }

    .Checkbox_checkbox__2DiPf {
        margin-right: 10px;
        height: 20px;
        width: 20px;
    }
}

.Search_textInput__3xAqz {
    margin: 0 auto 1.5rem auto;
    padding: 11px 18px;
    height: 44px;
    width: 100%;
    border: 1px solid var(--medium-grey);
    border-radius: 5px;
    font-size: 1rem;
}

.Search_selectAllRow__1vhYr {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}

.Search_section__3kBcB {
    width: 260px;
    margin: 0 auto;
}

.Search_sectionLabel__3Wnne {
    text-transform: lowercase;
    color: #808080;
    margin-top: 1rem;
    margin-bottom: 0;
}

.Search_sectionLabel__3Wnne::first-letter {
    text-transform: capitalize;
}

.Search_errorMessage__cV5EY {
    color: red;
    text-align: center;
}

.Search_submitBtn__kd1GX {
    background-color: #20a000;
    margin: 1.5rem 0;
    width: 100%;
    color: var(--white);
    border-radius: 5px;
    border: none;
    padding: 11px 61px;
    font-size: 1rem;
}

.Search_submitBtn__kd1GX:hover {
    cursor: pointer;
}

.Search_accordionBtn__1L21k {
    display: flex;
    align-items: center;
}

.Search_accordionBtn__1L21k:hover {
    cursor: pointer;
}

.Search_checkboxes__33xiN {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Search_textInput__3xAqz:focus-visible {
    outline: none;
}

.Search_arrow__1v0sg {
    margin-top: 1rem;
    height: 16px;
    width: 16px;
}

.Search_row__J90Rn {
    display: flex;
    justify-content: space-between;
}

.Search_inputGroup__2DB7a {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 1.5rem;
}

.Search_accordion__1lB6q {
    width: 100%;
}

.Search_boldLabel__Erkzc {
    color: var(--dark-purple);
    margin: 0;
}

@media screen and (min-width: 1024px) {
    .Search_section__3kBcB {
        width: 400px;
    }

    .Search_textInput__3xAqz {
        margin: 0 auto 2rem auto;
    }

    .Search_arrow__1v0sg {
        height: 20px;
        width: 20px;
    }

    .Search_inputGroup__2DB7a {
        padding-top: 4rem;
    }

    .Search_submitBtn__kd1GX {
        padding: 15px 112px;
        font-size: 1.25rem;
        border-radius: 10px;
        margin: 2rem 0;
    }
}

.Search_accordion__panel__31z6X {
    -webkit-animation-name: Search_slide-down__1CHHi;
            animation-name: Search_slide-down__1CHHi;
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0.5, 0, 0.2, 1);
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}

.Search_accordion__button__1tkTM[aria-expanded='true'] > .Search_accordionBtn__1L21k > .Search_arrow__1v0sg {
    -webkit-animation: Search_rotate180deg__3Pxv1 0.2s ease-in-out;
            animation: Search_rotate180deg__3Pxv1 0.2s ease-in-out;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}

@-webkit-keyframes Search_slide-down__1CHHi {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0px, -20px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
                transform: translate3d(0px, -20px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
    }
}

@keyframes Search_slide-down__1CHHi {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0px, -20px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
                transform: translate3d(0px, -20px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
    }
}

@-webkit-keyframes Search_rotate180deg__3Pxv1 {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
}

@keyframes Search_rotate180deg__3Pxv1 {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
}

.Pagination_pagination__2plzt {
    margin-bottom: 10rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.Pagination_middleLinks__vYb7U {
    display: flex;
}

.Pagination_paginationLink__1dT-L {
    margin: 0 1rem;
    text-decoration: underline;
}

.Pagination_placeholderDiv__Vf-8z {
    width: 2rem;
}

.History_tableWrapper__Q7zci {
    width: 100%;
    max-width: 1300px;
    margin-top: 1.5rem;
    margin-bottom: 5rem;
}

.History_historyTable__1DKfF {
    width: 100%;
}

.History_historyTable__1DKfF th {
    background-color: rgb(251, 251, 251);
    padding: 1.5rem 1rem;
    font-weight: 600;
}

.History_historyTable__1DKfF tbody tr {
    background-color: var(--white);
}

.History_historyTable__1DKfF tbody tr:hover {
    background-color: rgb(239, 247, 255);
}

tbody tr {
    border-top: 2px solid rgb(247, 247, 248);
}

td {
    padding: 1.5rem;
    font-weight: 400;
    text-transform: capitalize;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
    text-align: center;
    color: var(--dark-purple);
}

.History_tableButton__1FjbD {
    font-size: 0.875rem;
    padding: 8px 14px;
    border-radius: 10px;
    border: 1px solid var(--medium-grey);
}

.History_tableButton__1FjbD:hover {
    cursor: pointer;
}

.History_typeElement__WqMeA ~ .History_typeElement__WqMeA::before {
    content: ', ';
}

.History_typeArray__2x373 {
    max-width: 250px;
    line-height: 22px;
}

.History_searchWrapper__361dl {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}

.History_searchInput__3wBtP {
    border: 1px solid var(--medium-grey);
    border-radius: 50px;
    padding: 10px 1.5rem;
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    color: var(--dark-purple);
    font-family: 'Nunito Sans', sans-serif;
}

.History_searchInput__3wBtP:focus-visible {
    outline: none;
}

.History_dropDownTitle__1VTuo {
    font-weight: 400;
    color: var(--dark-purple);
    display: flex;
    align-items: center;
    width: 100%;
}

.History_dropDown__21ONi:hover .History_dropDownMenu__37XMu {
    opacity: 1;
    visibility: visible;
}

.History_dropDown__21ONi:hover .History_svgArrow__38EGJ {
    -webkit-animation: History_rotate180deg__1WAaj 0.1s ease-in-out;
            animation: History_rotate180deg__1WAaj 0.1s ease-in-out;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}

.History_dropDown__21ONi {
    margin-right: 2rem;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
}

.History_dropDownMenu__37XMu {
    opacity: 0;
    position: absolute;
    visibility: hidden;
    min-width: 205px;
    z-index: 4;
    position: absolute;
    top: 2.5rem;
    left: -4rem;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: var(--light-grey) 2px 2px;
}

.History_dropDownItem__S4vx5 {
    padding: 0.75rem 0;
    color: var(--dark-purple);
    font-size: 0.875rem;
    display: flex;
    align-items: center;
}

.History_dropDownItem__S4vx5,
label,
.History_dropDownCheckbox__21GCi:hover {
    cursor: pointer;
}

.History_dropDownItem__S4vx5:not(:last-child) {
    border-bottom: 1px solid var(--light-grey);
}

.History_dropDownCheckbox__21GCi {
    margin: 0 0.5rem 0 1.25rem;
    border-radius: 4px;
    -webkit-appearance: none;
            appearance: none;
    height: 18px;
    width: 18px;
    background-color: var(--light-grey);
}

.History_sortCheckbox__6ZwDB {
    -webkit-appearance: none;
            appearance: none;
    height: 20px;
    width: 20px;
}

.History_sortCheckbox__6ZwDB:checked {
    background-image: url(/static/media/check_24px.090b2df3.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.History_dropDownCheckbox__21GCi:checked {
    background-image: url("/static/media/Group 676.802c0232.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.History_sortOption__3r7zu {
    width: 100%;
}

#History_sortDropDown__3SI7w {
    width: 225px;
}

.History_dropDown__21ONi:nth-of-type(2) .History_dropDownMenu__37XMu {
    min-width: 225px;
}

.History_dropDown__21ONi:nth-of-type(2) .History_dropDownItem__S4vx5 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 1.125rem;
    padding-right: 0.875rem;
}

.History_noResultsMessage__2YIky {
    width: 100%;
    text-align: center;
    color: var(--dark-purple);
    font-size: 1.15rem;
    margin-top: 3rem;
}

.History_typeNumber__2uaVi {
    position: relative;
}

.History_typeNumber__2uaVi:hover {
    cursor: default;
}

.History_typeNumber__2uaVi:hover .History_hoverList__xu_WC {
    opacity: 1;
    visibility: visible;
}

.History_hoverList__xu_WC {
    position: absolute;
    left: 0;
    padding: 1rem;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: var(--light-grey) 2px 2px;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    text-transform: none;
}

@media screen and (min-width: 1024px) {
    .History_dropDownCheckbox__21GCi {
        height: 20px;
        width: 20px;
    }

    .History_dropDownItem__S4vx5 {
        font-size: 1rem;
    }

    .History_dropDownMenu__37XMu {
        min-width: 232px;
    }
}

@-webkit-keyframes History_rotate180deg__1WAaj {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
}

@keyframes History_rotate180deg__1WAaj {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
}


/* Dropdown Button */
.History_btn__2wi4K {
    font-size: 0.875rem;
    padding: 8px 14px;
    border-radius: 10px 0px 0px 10px;
    border: 1px solid var(--medium-grey);
}
.History_btnReverse__1dRQ9 {
    font-size: 0.875rem;
    padding: 8px 14px;
    border-radius: 0px 10px 10px 0px;
    border: 1px solid var(--medium-grey);
}


/* The container <div> - needed to position the dropdown content */
.History_dropdown__1Z9U2 {
    position: absolute;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.History_dropdownContent__1uGXK {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    z-index: 1;
}

/* Links inside the dropdown */
.History_dropdownContent__1uGXK a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.History_dropdownContent__1uGXK a:hover {background-color: #ddd}

/* Show the dropdown menu on hover */
.History_dropdown__1Z9U2:hover .History_dropdownContent__1uGXK {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.History_btn__2wi4K:hover, .History_dropdown__1Z9U2:hover .History_btn__2wi4K  {
    cursor: pointer;
}
.Progress_resultWrapper__3KJZf {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 11rem;
}

.Progress_percent__Kp4Gl {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 27px;
    margin: 0;
}

.Progress_headline__1QO-b {
    width: 100%;
    text-align: center;
    margin-top: 1.5rem;
}

.Progress_barContainer__1DRhY {
    width: 100%;
    max-width: 260px;
    height: 10px;
    background: var(--white);
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

}

.Progress_bar__2VNPO {
    background: var(--dark-purple);
    height: 10px;
    width: 10%;
    border-radius: 8px 0 0 8px;
}

.Progress_progressHeadline__2Blo- {
    width: 100%;
    max-width: 289px;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    line-height: 25px;
}

.Progress_trolleyContainer__34jo4 {
    position: relative;
    width: 100%;
    height: 24px;
    max-width: 260px;
}

.Progress_trolley__VqWTE {
    position: absolute;
    top: 0px;
    left: 0px;
}

@media screen and (min-width: 1024px) {
    .Progress_resultWrapper__3KJZf {
        margin-top: 13rem;
        max-width: 400px;
    }

    .Progress_trolleyContainer__34jo4 {
        max-width: 400px;
    }

    .Progress_barContainer__1DRhY {
        max-width: 400px;
    }

    .Progress_progressHeadline__2Blo- {
        max-width: 400px;
    }
}

.authpages_textInput__40zHQ {
  border: 1px solid;
  border-image-source: linear-gradient(180deg, #BABFC3 86.46%, #BBBFC3 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  box-shadow: 0px 1px 0px 0px #0000000D;
  border-radius: 4px;
  padding: 8px;
  width: 350px;
}

.authpages_container__3A0-6 {
  background-color: #F6F6F7;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px;
}

.authpages_formContainer__tuu74 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authpages_label__3MGVM {
  font-size: 14px;
  line-height: 20px;
  color: #202223;
  margin-top: 18px;
  margin-bottom: 4px;
}

.authpages_h1__3Cuhr {
  color: #202223;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}

.authpages_button__2k0RH {
  background-color: #272042;
  border: none;
  color: #FFFFFF;
  padding: 8px;
  width: 350px;
  margin-top: 30px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.8s;
  margin-bottom: 8px;
}

.authpages_button__2k0RH:hover:enabled {
  background: #2E294F radial-gradient(circle, transparent 1%, #2E294F 1%) center/15000%;
}

.authpages_button__2k0RH:active:enabled {
  background-color: #3B3560;
  background-size: 100%;
  transition: background 0s;
}

.authpages_button__2k0RH:disabled {
  background-color: #A49DCE;
}

@-webkit-keyframes authpages_ripple__VNtBP {
  to {
    -webkit-transform: scale(4);
            transform: scale(4);
    opacity: 0;
  }
}

@keyframes authpages_ripple__VNtBP {
  to {
    -webkit-transform: scale(4);
            transform: scale(4);
    opacity: 0;
  }
}

.authpages_link__LJLdh {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #272042;
}

.authpages_linkContainer__l6aV9 {
  margin-top: 22px;
  text-align: center;
}

.authpages_errorContainer__aXAUD {
  display: flex;
  margin-top: 4px;
}

input.authpages_errorInput__nRl_Q, .authpages_errorInput__nRl_Q input {
  background: #FFF4F4;
  border: 1px solid #D82C0D;
}

.authpages_errorText__3cVZf {
  color: #D82C0D;
  margin-left: 4px;
}

.authpages_qrContainer__2TO3E {
  margin: 8px;
}

.authpages_hintText__VWXs- {
  font-style: italic;
  font-size: 12px;
}

.authpages_authInput__3nchV {
  margin-top: 22px;
}

.pairing_code_container__3PRru { }

.pairing_code_input__31ft1 {
  width: 2.5ch;
  padding: 8px;
  border-radius: 8px;
  font-size: 30px;
  text-align: center;
  margin-right: 12px;
  border: 1px solid rgba(109, 113, 117, 0.5);
  text-transform: uppercase;
}
