@import-normalize;
@import 'variables.css';
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Nunito Sans', sans-serif;
    background-color: var(--bg-primary);
    font-size: 16px;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.container {
    max-width: 1440px;
    margin: 0 auto;
}

.wrapper {
    padding: 0 1.25rem;
}

.flex-center {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.bold {
    font-weight: bold;
}

h1 {
    font-size: 1.125rem;
}

h2 {
    font-size: 1rem;
}

h3 {
    font-size: 0.875rem;
}

@media screen and (min-width: 1024px) {
    h1 {
        font-size: 1.25rem;
    }

    h2 {
        font-size: 1.125rem;
    }

    h3 {
        font-size: 1rem;
    }

    .wrapper {
        padding: 0 4.375rem;
    }
}
