:root {
    --white: #ffffff;
    --blue-grey: #eff7ff;
    --light-grey: #f6f6f7;
    --medium-grey: #d4d4d4;
    --light-purple: #d9ceff;
    --dark-purple: #241d3c;
    --green: #20a000;
    --bg-primary: var(--light-grey);
}
