.textInput {
    margin: 0 auto 1.5rem auto;
    padding: 11px 18px;
    height: 44px;
    width: 100%;
    border: 1px solid var(--medium-grey);
    border-radius: 5px;
    font-size: 1rem;
}

.selectAllRow {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}

.section {
    width: 260px;
    margin: 0 auto;
}

.sectionLabel {
    text-transform: lowercase;
    color: #808080;
    margin-top: 1rem;
    margin-bottom: 0;
}

.sectionLabel::first-letter {
    text-transform: capitalize;
}

.errorMessage {
    color: red;
    text-align: center;
}

.submitBtn {
    background-color: #20a000;
    margin: 1.5rem 0;
    width: 100%;
    color: var(--white);
    border-radius: 5px;
    border: none;
    padding: 11px 61px;
    font-size: 1rem;
}

.submitBtn:hover {
    cursor: pointer;
}

.accordionBtn {
    display: flex;
    align-items: center;
}

.accordionBtn:hover {
    cursor: pointer;
}

.checkboxes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.textInput:focus-visible {
    outline: none;
}

.arrow {
    margin-top: 1rem;
    height: 16px;
    width: 16px;
}

.row {
    display: flex;
    justify-content: space-between;
}

.inputGroup {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 1.5rem;
}

.accordion {
    width: 100%;
}

.boldLabel {
    color: var(--dark-purple);
    margin: 0;
}

@media screen and (min-width: 1024px) {
    .section {
        width: 400px;
    }

    .textInput {
        margin: 0 auto 2rem auto;
    }

    .arrow {
        height: 20px;
        width: 20px;
    }

    .inputGroup {
        padding-top: 4rem;
    }

    .submitBtn {
        padding: 15px 112px;
        font-size: 1.25rem;
        border-radius: 10px;
        margin: 2rem 0;
    }
}

.accordion__panel {
    animation-name: slide-down;
    animation-timing-function: cubic-bezier(0.5, 0, 0.2, 1);
    animation-duration: 300ms;
    animation-iteration-count: 1;
    animation-fill-mode: both;
}

.accordion__button[aria-expanded='true'] > .accordionBtn > .arrow {
    animation: rotate180deg 0.2s ease-in-out;
    animation-fill-mode: both;
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translate3d(0px, -20px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
    }

    to {
        opacity: 1;
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
    }
}

@keyframes rotate180deg {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}
